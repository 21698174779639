import React from "react";
import classNames from "classnames";
import { Container, Text } from "@atoms";

const CopyGrid = ({
  bgColor,
  className: _className,
  heading,
  items,
  paddingTop,
}) => {
  return (
    <section
      className={classNames(
        "pb-20 md:pb-28",
        {
          "pt-20 md:pt-28": paddingTop,
          "bg-green": bgColor === "green",
          "bg-gray": bgColor === "gray",
          "bg-white": bgColor === "white",
        },
        _className
      )}
    >
      <Container>
        <div className="flex flex-col gap-16 md:gap-20">
          {heading && (
            <Text variant="h2" className="text-center">
              {heading}
            </Text>
          )}
          {!!items.length && (
            <div className="flex flex-wrap justify-center gap-16 md:gap-20">
              {items?.map(item => {
                return (
                  <div
                    key={item.uid}
                    className="flex-auto md:flex-[0_1_calc(50%-3rem)]"
                  >
                    <Text richText>{item.copy}</Text>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </Container>
    </section>
  );
};

CopyGrid.defaultProps = {};

export default CopyGrid;
